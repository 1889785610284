<template>
  <CardContainer :title="title" v-if="stats">
    <template slot="chart">
      <CChartPie
        :datasets="chartData"
        :labels="chartLabel"
        :options="chartOption"
      />
    </template>
  </CardContainer>
</template>

<script>
import CardContainer from '@/containers/DashboardCard.vue'
import { CChartPie } from '@coreui/vue-chartjs'

export default {
  name: 'CompCardPieChart',
  components: {
    CardContainer,
    CChartPie
  },
  props: {
    title: {type:String,default:'Gender'},
    stats: {type:Array},
    colors: {type:Array},
    labels: {type:Array},
    labelValue: {type:Boolean,default:false}
  },
  computed: {
    // *** datasets configurations ***
    // Name	                    Type         Scriptable      Indexable      Default
    // -----------------------------------------------------------------------------------------
    // backgroundColor          Color        Yes             Yes            'rgba(0, 0, 0, 0.1)'
    // borderAlign              string       Yes             Yes            'center'
    // borderColor              Color        Yes             Yes            '#fff'
    // borderWidth              number       Yes             Yes            2
    // data                     number[]     -               -              required
    // hoverBackgroundColor     Color        Yes             Yes            undefined
    // hoverBorderColor         Color        Yes             Yes            undefined
    // hoverBorderWidth         number       Yes             Yes            undefined
    // weight                   number       -               -              1
    chartData() {
      return [
        {
          backgroundColor: this.colors,
          data: this.stats
        }
      ];
    },
    chartLabel() {
      const rtn = [];
      for (let i=0; i<this.stats.length; i++) {
        if (this.labelValue)
          rtn.push(`${this.labels[i]} ${this.stats[i]}`);
        else {
          rtn.push(this.labels[i]);
        }
      }
      return rtn;
    },
    chartOption() {
      return {
        legend: {
          position: 'right',
          align: 'center',
          labels: {
            fontSize: 12
          }
        }
      };
    }
  }
}
</script>
